import React, { FC, PureComponent } from 'react';
import Menu, { Item as MenuItem } from 'rc-menu';
import 'rc-dropdown/assets/index.css';
import './site-menu.css';
import GetPricingButton from '../buttons/get-pricing-button';
import Dropdown from 'rc-dropdown';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { Route } from '../../models/site-links';
import { gaButtonEvent } from '../../services/visitor-info';

interface MenuProps {
  link: string;
  name: string;
}

export const SiteMenu = (props) => {
  const MenuLink: FC<MenuProps> = (props) => {
    return (
      <AniLink
        onClick={gaButtonEvent(props.name, `DesktopMenu:${props.link}`)}
        fade
        to={props.link}
      >
        {props.name}
      </AniLink>
    );
  };

  const solutionsMenuItems = [
    <MenuItem key="1">
      <MenuLink name="Nightclubs &amp; Bars" link={Route.IdScannerBars} />
    </MenuItem>,
    <MenuItem key="2">
      <MenuLink name="Vape Shops" link={Route.IndustryVape} />
    </MenuItem>,
    <MenuItem key="3">
      <MenuLink name="Automotive" link={Route.IndustryAutomotive} />
    </MenuItem>,
    <MenuItem key="4">
      <MenuLink name="Property Management" link={Route.IndustryProperty} />
    </MenuItem>,
    <MenuItem key="5">
      <MenuLink name="Dispensaries" link={Route.IndustryCannabis} />
    </MenuItem>,
    <MenuItem key="6">
      <MenuLink name="Retail" link={Route.IndustryRetail} />
    </MenuItem>,
    <MenuItem key="7">
      <MenuLink name="Financial Services" link={Route.IndustryAntiFraud} />
    </MenuItem>,
    <MenuItem key="8">
      <MenuLink name="Telecom" link={Route.IndustryTelecom} />
    </MenuItem>,
    <MenuItem key="9">
      <MenuLink name="Fake ID Detection" link={Route.FakeIdScanner} />
    </MenuItem>,
    <MenuItem key="10">
      <MenuLink name="Anti-Fraud" link={Route.AntiFraud} />
    </MenuItem>,
    <MenuItem key="11">
      <MenuLink name="Access Control" link={Route.AccessControl} />
    </MenuItem>,
    <MenuItem key="12">
      <MenuLink name="IVAC" link={Route.IVAC} />
    </MenuItem>,
    <MenuItem key="13">
      <MenuLink name="Handheld" link={Route.Handheld} />
    </MenuItem>,
  ];

  const technologyMenuItems = [
    <MenuItem key="1">
      <AniLink fade to={Route.Advantages}>
        Advantage
      </AniLink>
    </MenuItem>,
    <MenuItem key="2">
      <AniLink fade to={Route.Developer}>
        Developers
      </AniLink>
    </MenuItem>,
  ];

  const solutionsMenu = <Menu selectable={false}>{solutionsMenuItems}</Menu>;
  const technologyMenu = <Menu selectable={false}>{technologyMenuItems}</Menu>;
  const menuItemsClasses =
    'menu-items text-body tab:mx-4 desklg:mx-6 tab:text-md desklg:text-xl tracking-widest transition-color text-shades-1';

  return (
    <nav className={props.className}>
      <div className="menu-contact-buttons">
        <a
          onClick={() => gaButtonEvent('18777789798', 'DesktopMenu:PhoneNumber')}
          href="tel:18777789798"
          className="hidden text-body xl:inline-block text-primary tab:text-md desklg:text-xl hover:text-secondary transition-color xl:pr-12 xxl:pl-8 xxl:pr-20"
        >
          1-877-778-9798
        </a>
      </div>
    </nav>
  );
};
