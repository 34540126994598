export enum Site {
  Global = 'https://www.patronscan.com',
}

export enum Route {
  Cancel = '/cancel',
  CancelSuccess = '/cancel-success',
  ReferralSuccess = '/referral-success',
  Success = '/success',
  Home = 'https://www.patronscan.com',
}
