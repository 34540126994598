import React, { useState } from 'react';
import * as patronscanLogo from '../../images/logo-firefox-fix.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { SiteMenu } from './site-menu';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
//import { HamburgerMenu } from './hamburger-menu';
import './header.css';

const Header = () => {
  const [menuVisible, setMenuVisibility] = useState(false);

  const toggleMenuVisibility = () => setMenuVisibility(!menuVisible);

  return (
    <header className="fixed bg-white shadow-lg z-50 w-full header-bar">
      <div className="flex flex-row justify-between items-center px-4 py-2 xl:py-0 xl:px-6 header-bar-contents">
        <a href={'https://patronscan.com'} rel="noreferrer" className="xl:w-auto ml-4">
          <img
            className="desk:w-headerLogo tab:w-headerLogo xr:w-headerLogoMobile h-14 max-h-14 opacity-100 hover:opacity-75 transition-opacity"
            src={patronscanLogo}
            alt="Patronscan Logo"
          />
        </a>
        <SiteMenu className="w-full hidden desk:flex desk:flex-row desk:justify-end desk:items-center" />
      </div>
    </header>
  );
};

export default Header;
