import React, { FC, useEffect } from 'react';
import Header from './header';
import './layout.css';
import SiteFooter from './site-footer';
import { recordGoogleClickIdentifier } from '../../services/google-click-identifier';
import { recordVisitorInfo, gaViewEvent } from '../../services/visitor-info';

interface LayoutProps {}

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const Layout: FC<LayoutProps> = (props) => {
  useEffect(() => {
    // Record or load the Google Click Identifier if exists
    // recordGoogleClickIdentifier();
    // recordVisitorInfo();
    // gaViewEvent();
    // window.dataLayer = window.dataLayer || []
    // window.dataLayer.push({ event: 'optimize.activate' })
  }, []);

  return (
    <div>
      <Header />
      <main className="font-body bg-white font-hairline">{props.children}</main>
    </div>
  );
};

export default Layout;
